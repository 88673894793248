<template>
  <!-- :md="size === 2 ? 6 : size === 3 ? 4 : undefined" -->
  <v-col cols="12" :md="size === 2 ? 6 : size === 3 ? 4 : undefined">
    <!-- {{size}} -->

    <!-- <base-card
      :height="value.prominent ? 450 : 350"
      color="grey lighten-1"
      dark
      href="#!"
    > -->
    <base-card @click.prevent="goDetail(value.id, propcurrPage)">
      <!-- <v-img
        :src="require(`@/assets/images/logo.jpg`)"
        height="100%"
        gradient="rgba(0, 0, 0, .42), rgba(0, 0, 0, .42)"
      > -->
      <!-- propcurrPage > {{propcurrPage}} -->
      <!-- class="pa-2" -->
      <!-- tile -->
      <v-card outlined>
        <v-row align="center" class="mx-auto" dense tile>
          <v-col cols="12">
            <img
              v-if="value.fileUrl.length > 0"
              :src="value.fileUrl"
              :alt="value.name"
              height="100%"
              width="100%"
              class="white--text align-end"
              style="cursor: pointer;"
              gradient="rgba(0, 0, 0, .42), rgba(0, 0, 0, .42)"
            />
            <p></p>
            <div class="text-h7 px-4 pt-4 ">
              {{ value.title }}
            </div>
            <p></p>
          </v-col>
        </v-row>
      </v-card>
      <!-- <v-row
          v-if="!value.prominent"
          class="fill-height text-right ma-0"
        >
          <v-col cols="12">
            <v-chip
              label
              class="mx-0 mb-2 text-uppercase"
              color="grey darken-3"
              text-color="white"
              small
              @click.stop=""
            >
              {{ value.category }}
            </v-chip>

            <h3 class="title font-weight-bold mb-2">
              {{ value.title }}
            </h3>

            <div class="caption">
              {{ value.author }}<br>Date
            </div>
          </v-col>

          <v-col align-self="end">
            <v-chip
              class="text-uppercase ma-0"
              color="primary"
              label
              small
              @click.stop=""
            >
              Read More
            </v-chip>
          </v-col>
        </v-row> -->
      <!-- </v-img> -->
    </base-card>
  </v-col>
</template>

<script>
export default {
  name: "BbsCard",

  props: {
    size: {
      type: Number,
      required: true
    },
    value: {
      type: Object,
      default: () => ({})
    },
    propcurrPage: {
      type: Number,
      required: true,
      default: 0
    },
    currPage: 0
  },
  methods: {
    goDetail(id, propcurrPage) {
      // console.log('godetail>'+propcurrPage);
      // this.$router.push({path:'/BbsDetail',params: { id: id}});
      this.$router.push({
        path: "/bbsdetail/" + id + "/" + propcurrPage
      });
      // params: { id: 123 }
    }
  }
};
</script>

<style>
.v-image__image {
  transition: 0.3s linear;
}
</style>
